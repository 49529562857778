import { TravelFilterResponse, TravelFilterSelections, TravelFilterState, TravelFilterTravel } from '@/features/travelFilter/types'
import { Draft } from 'immer'
import { NextRouter } from 'next/router'
import { getTravelDisplayRelevantDates, orderByStartDate } from '@/lib/helpers/travel'
import cloneDeep from 'lodash/cloneDeep'

export const toggleUpdateUrlTrigger = (state: Draft<TravelFilterState>) => {
  state.updateUrlTrigger = state.updateUrlTrigger === true ? 1 : true
}

export const setTravelFilterStateResponse = (state: Draft<TravelFilterState>, filterResponse: TravelFilterResponse) => {
  state.counts = filterResponse.counts
  state.filters = filterResponse.filters
  state.travels = sanitizeTravelFilterResponseTravels(filterResponse.travels)
}

const sanitizeTravelFilterResponseTravels = (travels: TravelFilterTravel[]) => {
  return orderByStartDate(
    travels.map((travel) => {
      // if already specifically selected date, return as is
      if (travel.startDate !== travel.dates[0].startDate) {
        return travel
      }
      const dates = getTravelDisplayRelevantDates(travel.dates)
      if (dates[0]) {
        travel = cloneDeep(travel)
        travel.startDate = dates[0].startDate
        travel.endDate = dates[0].endDate
      }
      return travel
    })
  )
}

export const getTravelFilterParamsFromRouter = (router: NextRouter) => {
  const queryString = router.asPath.substring(router.pathname.length)
  const searchParams = new URLSearchParams(queryString)

  const params = {
    query: [''],
    destinations: [],
    travelTypes: [],
    months: [],
  }
  let hasParams = false
  for (let p of searchParams) {
    hasParams = true
    // @ts-ignore
    params[p[0]] = p[1].split('|')
  }

  return {
    hasParams: hasParams,
    query: params.query[0],
    filterSelections: {
      destinations: params.destinations,
      travelTypes: params.travelTypes,
      months: params.months,
    },
  }
}

export const buildTravelFilterUrlQuery = (filterSelections: TravelFilterSelections, query?: string) => {
  let queryString = ''

  // filters
  Object.keys(filterSelections).forEach((key) => {
    if (filterSelections[key].length) {
      if (queryString) {
        queryString += '&'
      }

      let valueString = ''
      filterSelections[key].forEach((value, index) => {
        if (index > 0) {
          valueString += '|'
        }
        valueString += value
      })
      queryString += `${key}=${encodeURIComponent(valueString)}`
    }
  })

  // query
  if (query) {
    if (queryString) {
      queryString += '&'
    }
    queryString += `query=${encodeURIComponent(query)}`
  }
  return queryString
}
