// language=GraphQL
export const ALL_AVAILABILITY_STATUS_QUERY = `query AllTravelAvailabilityStatus {
  allTravelAvailabilityStatus {
    isDefaultStatus
    isDefaultWaitlistFallback
    label
    status
    tenantSettings {
      info
      label
      submitButtonLabel
      switchToStatusInfo
      tenant {
        domain
        name
        id
      }
    }
  }
}`

// language=GraphQL
export const RATIO_SUMMARY_QUERY = `
  query RatioSummaryQuery($clientState: JSON!) {
    ratioSummary(clientState: $clientState) {
      json
    }
  }`

// language=GraphQL
export const SUBMIT_BOOKING = `
  mutation PlaceBooking($tenantId: ID!, $json: JSON!) {
    placeBooking(tenantId: $tenantId, json: $json) {
      bookingId
      orderNo
      totalPrice
      success
      message
    }
  }`

// language=GraphQL
export const INIT_BOOKING_PAYMENT_MUTATION = `
  mutation InitBookingPayment($input: InitBookingPaymentInput) {
    initBookingPayment(input: $input) {
      success
      message
      transactionId
    }
  }`

// language=GraphQL
export const CONTINGENTS_QUERY = `query Query($ratioTravelId: ID!, $dateId: ID!) {
  packages: packageContingents(ratioTravelId: $ratioTravelId, dateId: $dateId) {
    ratioPackageNo
    available
  }
  services: serviceContingents(ratioTravelId: $ratioTravelId, dateId: $dateId) {
    ratioServiceNo
    available
  }
}`

// language=GraphQL
export const TRAVEL_QUERY = `query Travel($travelId: ID!, $tenantId: ID!) {
  travel(id: $travelId) {
    id
    name
    title
    minPrice
    seoSettings {
      urlSlug
    }
    availabilityStatus {
      id
      label
      status
      isDefaultStatus
      isDefaultWaitlistFallback
      tenantSettings {
        id
        tenant {
          id
        }
        label
        info
        switchToStatusInfo
        submitButtonLabel
      }
    }
    pricing {
      isTravelDocumentationAvailable
      travels {
        id
        transportTypeLabel
      }
      esimSettings {
        regions {
          id
        }
      }
      bargainSettings {
        active
        untilDaysBeforeStart
        bargainTier {
          discount
        }
      }
      branding {
        logoWidth
        logo {
          publicId
          title
          description
        }
      }
      customDataConfig {
        useTravellerForm
        travellerForm {
          fields {
            inputType
            name
            label
            info
            value
            required
            options {
              value
              label
            }
          }
        }
        useBookingForm
        bookingForm {
          fields {
            inputType
            name
            label
            info
            value
            required
            options {
              value
              label
            }
          }
        }
      }
      ageGroupSettings {
        id
        freeUntilAge
        ageGroups {
          id
          name
          type
          minAge
          maxAge
        }
      }
    }
  }
  ratioConfig(id: 1) {
    bargainConfig {
      active
      priceCap
      serviceCodePrefix
    }
    esimConfig {
      enabled
      regions {
        id
        name
        serviceCodePrefix
      }
    }
  }
  tenant(id: $tenantId) {
    onlinePaymentConfig {
      enabled
      infoText
    }
  }
}`

// language=GraphQL
export const PRICING_TRAVEL_QUERY = `query RatioTravel($ratioTravelId: ID!) {
  ratioTravel(id: $ratioTravelId) {
    id
    ratioCatalogBundle {
      json
    }
    dates {
      id
      dateNo
      startDate
      endDate
      includesExtension
      json
      availabilityStatus {
        label
        status
        tenantSettings {
          info
          label
          submitButtonLabel
          switchToStatusInfo
          tenant {
            domain
            name
            id
          }
        }
      }
      extension {
        id
        label
        info
        accommodationMapItems {
          id
          baseAccommodation {
            id
            ratioPackageNo
            name
            label
            info
          }
          extensionAccommodation {
            id
            ratioPackageNo
            name
            label
            info
            extensionAccommodationServiceNo
          }
        }
        travelExtension {
          id
          name
          description
          duration
          highlightIds
          highlights {
            id
            text
          }
          imageId
          image {
            id
            assetId
            publicId
            title
            description
            format
            url
            width
            height
          }
        }
      }
      extensions {
        id
        label
        info
        accommodationMapItems {
          id
          baseAccommodation {
            id
            ratioPackageNo
            name
            label
            info
          }
          extensionAccommodation {
            id
            ratioPackageNo
            name
            label
            info
            extensionAccommodationServiceNo
          }
        }
        travelExtension {
          id
          name
          description
          duration
          highlightIds
          highlights {
            id
            text
          }
          imageId
          image {
            id
            assetId
            publicId
            title
            description
            format
            url
            width
            height
          }
        }
      }
      extensionBaseDate {
        id
        dateNo
        startDate
        endDate
      }
    }
    accommodations {
      id
      ratioPackageNo
      name
      label
      info
      isFamilyPackageAccommodation
      isBabyPackage
      capacitySettings {
        id
        totalCapacity
        rangesAllowed
        ageGroupCapacities {
          id
          ageGroup {
            id
            name
            type
            minAge
            maxAge
          }
          minCapacity
          maxCapacity
        }
        ageGroupedTotalCapacities {
          id
          ageGroups {
            id
            name
            type
            minAge
            maxAge
          }
          capacity
        }
        partialOccupancyAllowed
      }
      addons {
        id
        ratioServiceNo
        name
        label
        info
        calculationSettings {
          id
          type
          considerHotelNights
        }
      }
    }
    familyPackages {
      id
      ratioServiceNo
      name
      label
      info
      accommodation {
        id
        ratioPackageNo
        name
        label
        info
      }
      capacitySettings {
        id
        totalCapacity
        rangesAllowed
        ageGroupCapacities {
          id
          ageGroupId
          ageGroup {
            id
            name
            type
            icon
            minAge
            maxAge
          }
          minCapacity
          maxCapacity
        }
        ageGroupedTotalCapacities {
          id
          ageGroupIds
          ageGroups {
            id
            name
            type
            icon
            minAge
            maxAge
          }
          capacity
        }
        partialOccupancyAllowed
        partialOccupancyAllowedAgeGroups {
          id
          name
          icon
          type
          minAge
          maxAge
        }
      }
      addons {
        id
        ratioServiceNo
        name
        label
        info
        calculationSettings {
          id
          type
          considerHotelNights
        }
      }
    }
    personalAddons {
      id
      ratioServiceNo
      name
      label
      info
      allowedAgeGroups {
        id
        name
        type
        icon
        minAge
        maxAge
      }
      calculationSettings {
        id
        type
        considerHotelNights
      }
      mandatorySettings {
        id
        isMandatory
        useRules
        rules {
          id
          checkDateRange
          startDate
          endDate
          checkAccommodationTypes
          accommodations {
            id
            ratioPackageNo
            name
            label
            info
          }
          familyPackages {
            id
            ratioServiceNo
            name
            label
            info
          }
          checkAccommodationAddons
          accommodationAddons {
            id
            ratioServiceNo
            name
            label
            info
          }
          checkAgeGroups
          ageGroups {
            id
            name
            type
            icon
            minAge
            maxAge
          }
          useAgeFilter
          ageFilter {
            id
            minAge
            maxAge
          }
          checkTravelDate
          travelDates {
            id
          }
        }
      }
    }
    accommodationAddons {
      id
      ratioServiceNo
      name
      label
      info
      calculationSettings {
        id
        type
        considerHotelNights
      }
    }
    bookingAddons {
      id
      ratioServiceNo
      name
      label
      info
      calculationSettings {
        id
        type
        considerHotelNights
      }
      mandatorySettingsId
      mandatorySettings {
        id
        isMandatory
        useRules
        rules {
          id
          checkDateRange
          startDate
          endDate
          checkAccommodationTypes
          accommodations {
            id
            ratioPackageNo
            name
            label
            info
          }
          familyPackages {
            id
            ratioServiceNo
            name
            label
            info
          }
          checkAccommodationAddons
          accommodationAddons {
            id
            ratioServiceNo
            name
            label
            info
          }
          checkAgeGroups
          ageGroups {
            id
            name
            type
            icon
            minAge
            maxAge
          }
          useAgeFilter
          ageFilter {
            id
            minAge
            maxAge
          }
          checkTravelDate
          travelDates {
            id
          }
        }
      }
    }
    collectiveAddons {
      id
      label
      info
      ageGroupServices {
        id
        ageGroupId
        ageGroup {
          icon
          id
          name
          type
          minAge
          maxAge
        }
        ratioServiceNo
      }
      calculationSettings {
        id
        type
        considerHotelNights
      }
      mandatorySettingsId
      mandatorySettings {
        id
        isMandatory
        useRules
        rules {
          id
          checkDateRange
          startDate
          endDate
          checkAccommodationTypes
          accommodations {
            id
            ratioPackageNo
            name
            label
            info
          }
          familyPackages {
            id
            ratioServiceNo
            name
            label
            info
          }
          checkAccommodationAddons
          accommodationAddons {
            id
            ratioServiceNo
            name
            label
            info
          }
          checkAgeGroups
          ageGroups {
            id
            name
            type
            icon
            minAge
            maxAge
          }
          useAgeFilter
          ageFilter {
            id
            minAge
            maxAge
          }
          checkTravelDate
          travelDates {
            id
          }
        }
      }
    }
    extensions {
      id
      label
      info
      travelExtensionId
      travelExtension {
        id
        name
        description
        highlightIds
        highlights {
          id
          text
        }
        imageId
        image {
          id
          assetId
          publicId
          title
          description
          format
          url
          width
          height
        }
      }
    }
    personalDiscounts {
      id
      ratioServiceNo
      name
      label
      info
      mandatorySettings {
        id
        isMandatory
        useRules
        rules {
          id
          checkDateRange
          startDate
          endDate
          checkAccommodationTypes
          accommodations {
            id
            ratioPackageNo
            name
            label
            info
          }
          familyPackages {
            id
            ratioServiceNo
            name
            label
            info
          }
          checkAccommodationAddons
          accommodationAddons {
            id
            ratioServiceNo
            name
            label
            info
          }
          checkAgeGroups
          ageGroups {
            id
            name
            type
            icon
            minAge
            maxAge
          }
          useAgeFilter
          ageFilter {
            id
            minAge
            maxAge
          }
          checkTravelDate
          travelDates {
            id
          }
        }
      }
    }
    catalogCode
    travelCode
    name
    transportType
    transportTypeLabel
  }
}`
