// language=GraphQL
export const BESTSELLERS_QUERY = `query BestsellerTravels($tenantId: ID!) {
  bestsellerTravels(tenantId: $tenantId) {
    name
    urlSlug
    minPrice
    currency
    startDate
    endDate
    dates {
      startDate
      endDate
      includesExtension
    }
    accommodations {
      name
    }
    travelGuides {
      fullName
    }
    highlights {
      text
    }
    mainImage {
      publicId
      title
      description
    }
  }
}`
