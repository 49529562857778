import { TravelFilterSelections } from '@/features/travelFilter/types'

export const buildTravelFilterRequestVariables = (filterSelections?: TravelFilterSelections, query: string = '') => {
  if (!filterSelections) {
    filterSelections = {
      destinations: [],
      travelTypes: [],
      months: [],
    }
  }
  const filterVars = Object.keys(filterSelections).map((key) => {
    return {
      name: key,
      // @ts-ignore
      selection: filterSelections[key].map(String),
    }
  })
  return { tenantId: process.env.NEXT_PUBLIC_TENANT_ID, query: query, filters: filterVars }
}

// language=GraphQL
export const TRAVEL_FILTER_QUERY = `query TravelFilter($tenantId: ID!, $query: String, $filters: [FilterInput]) {
  travelFilter(tenantId: $tenantId, query: $query, filters: $filters) {
    success
    counts {
      total
      filteredTotal
    }
    travels {
      name
      urlSlug
      startDate
      endDate
      label
      minPrice
      currency
      accommodations {
        name
      }
      travelGuides {
        fullName
      }
      mainImage {
        publicId
        title
        description
      }
      dates {
        startDate
        endDate
        includesExtension
      }
    }
    filters {
      name
      options {
        label
        value
        itemCount
      }
    }
  }
}`
