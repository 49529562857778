const IconSvgSprite = () => {
  return (
    <div hidden>
      <svg xmlns="http://www.w3.org/2000/svg">
        <symbol id="down-arrow" viewBox="0 0 128 128">
          <title>down-arrow</title>
          <path
            id="Down_Arrow_3_"
            d="m64 88c-1.023 0-2.047-.391-2.828-1.172l-40-40c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0l37.172 37.172 37.172-37.172c1.563-1.563 4.094-1.563 5.656 0s1.563 4.094 0 5.656l-40 40c-.781.781-1.805 1.172-2.828 1.172z"
          />
        </symbol>
        <symbol id="up-arrow" viewBox="0 0 128 128">
          <title>up-arrow</title>
          <path
            id="Up_Arrow_3_"
            d="m104 88c-1.023 0-2.047-.391-2.828-1.172l-37.172-37.172-37.172 37.172c-1.563 1.563-4.094 1.563-5.656 0s-1.563-4.094 0-5.656l40-40c1.563-1.563 4.094-1.563 5.656 0l40 40c1.563 1.563 1.563 4.094 0 5.656-.781.781-1.805 1.172-2.828 1.172z"
          />
        </symbol>
        <symbol id="chevron" viewBox="0 0 128 128">
          <title>chevron</title>
          <path
            id="Right_Arrow_4_"
            d="m44 108c-1.023 0-2.047-.391-2.828-1.172-1.563-1.563-1.563-4.094 0-5.656l37.172-37.172-37.172-37.172c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0l40 40c1.563 1.563 1.563 4.094 0 5.656l-40 40c-.781.781-1.805 1.172-2.828 1.172z"
          />
        </symbol>
        <symbol id="left-chevron" viewBox="0 0 128 128">
          <title>left-chevron</title>
          <path
            id="Left_Arrow_4_"
            d="m84 108c-1.023 0-2.047-.391-2.828-1.172l-40-40c-1.563-1.563-1.563-4.094 0-5.656l40-40c1.563-1.563 4.094-1.563 5.656 0s1.563 4.094 0 5.656l-37.172 37.172 37.172 37.172c1.563 1.563 1.563 4.094 0 5.656-.781.781-1.805 1.172-2.828 1.172z"
          />
        </symbol>
        <symbol id="like-1" viewBox="0 0 512 512">
          <title>like-1</title>
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M376.951,28.507h-0.899c-66.027,0-102.942,48.32-120.049,81.033 c-17.408-32.714-54.022-81.033-120.049-81.033h-1.199C63.324,29.108,3.792,90.032,0.191,167.164 c-4.503,94.538,70.937,158.164,183.782,254.204c19.509,16.807,40.218,34.515,62.126,53.423l9.905,8.703l9.905-8.703 c21.608-18.908,42.617-36.616,62.126-53.423c112.844-96.04,188.278-159.666,183.775-254.204 C508.206,90.031,448.381,29.107,376.951,28.507z"
            fill="#f53c4b"
            data-original="#ff3636"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M511.808,167.164c4.503,94.538-70.93,158.164-183.775,254.204 c-19.509,16.807-40.518,34.515-62.126,53.423l-9.905,8.703V109.54c17.107-32.714,54.022-81.033,120.049-81.033h0.899 C448.381,29.107,508.206,90.031,511.808,167.164z"
            fill="#e41f30"
            data-original="#f40000"
          />
        </symbol>
        <symbol id="like" viewBox="0 0 512 512">
          <title>like</title>
          <path d="M511.812,167.165c-1.702-36.088-16.232-70.306-40.914-96.353c-25.567-26.981-59.251-41.993-94.847-42.272 c-34.009-0.257-63.948,12.15-88.992,36.905c-14.257,14.091-24.699,30.11-32.057,44.104c-7.358-13.994-17.8-30.013-32.057-44.104 c-25.047-24.754-55.004-37.158-88.993-36.905c-35.545,0.278-68.917,15.336-93.967,42.4C16.029,96.82,1.896,130.993,0.19,167.165 c-4.464,94.635,70.036,158.12,182.806,254.216c19.824,16.893,40.324,34.362,62.129,53.441l9.877,8.643l9.877-8.643 c23.121-20.23,44.813-38.641,65.789-56.445C442.411,323.538,516.232,260.884,511.812,167.165z M439.818,275.475 c-29.97,36.353-73.478,73.279-128.562,120.03c-18.057,15.325-36.642,31.099-56.253,48.129 c-18.29-15.892-35.664-30.698-52.55-45.087c-55.77-47.524-99.819-85.061-130.02-121.835 c-30.986-37.731-44.024-71.079-42.276-108.133c2.89-61.271,48.585-109.605,104.029-110.039c0.271-0.002,0.533-0.003,0.803-0.003 c57.965,0,87.525,49.314,100.005,78.802c3.424,8.09,11.278,13.318,20.007,13.318c8.729,0,16.583-5.228,20.007-13.318 c12.48-29.49,42.033-78.801,100.004-78.801c0.267,0,0.535,0.001,0.803,0.003c55.624,0.435,103.188,49.799,106.029,110.039 C483.57,205.158,470.609,238.125,439.818,275.475z" />
        </symbol>
        <symbol id="facebook" viewBox="0 0 512 512">
          <title>facebook</title>
          <path d="M285.999,512h-90c-8.291,0-15-6.709-15-15V300h-45c-8.291,0-15-6.709-15-15v-90c0-8.291,6.709-15,15-15h45v-75 c0-57.891,47.109-105,105-105h90c8.291,0,15,6.709,15,15v90c0,8.291-6.709,15-15,15h-75v60h75c4.409,0,8.599,1.934,11.44,5.303 c2.856,3.369,4.087,7.808,3.354,12.158l-15,90c-1.201,7.236-7.456,12.539-14.795,12.539h-60v197 C300.999,505.291,294.29,512,285.999,512z" />
        </symbol>
        <symbol id="facebook-1" viewBox="0 0 512 512">
          <title>facebook-1</title>
          <path
            d="M285.999,512h-90c-8.291,0-15-6.709-15-15V300h-45c-8.291,0-15-6.709-15-15v-90c0-8.291,6.709-15,15-15h45v-75 c0-57.891,47.109-105,105-105h90c8.291,0,15,6.709,15,15v90c0,8.291-6.709,15-15,15h-75v60h75c4.409,0,8.599,1.934,11.44,5.303 c2.856,3.369,4.087,7.808,3.354,12.158l-15,90c-1.201,7.236-7.456,12.539-14.795,12.539h-60v197 C300.999,505.291,294.29,512,285.999,512z"
            fill="#d3dadb"
            data-original="#000000"
          />
        </symbol>
        <symbol id="instagram" viewBox="0 0 512 512">
          <title>instagram</title>
          <path d="m392 512h-272c-66.168 0-120-53.832-120-120v-272c0-66.168 53.832-120 120-120h272c66.168 0 120 53.832 120 120v272c0 66.168-53.832 120-120 120zm-272-472c-44.112 0-80 35.888-80 80v272c0 44.112 35.888 80 80 80h272c44.112 0 80-35.888 80-80v-272c0-44.112-35.888-80-80-80zm277 50c-13.807 0-25 11.193-25 25 0 13.807 11.193 25 25 25 13.807 0 25-11.193 25-25 0-13.807-11.193-25-25-25zm-141 296c-71.683 0-130-58.317-130-130 7.14-172.463 252.886-172.413 260 .001 0 71.682-58.317 129.999-130 129.999zm0-220c-49.626 0-90 40.374-90 90 4.944 119.397 175.074 119.362 180-.001 0-49.625-40.374-89.999-90-89.999z" />
        </symbol>
        <symbol id="instagram-1" viewBox="0 0 512 512">
          <title>instagram-1</title>
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m392 512h-272c-66.168 0-120-53.832-120-120v-272c0-66.168 53.832-120 120-120h272c66.168 0 120 53.832 120 120v272c0 66.168-53.832 120-120 120zm-272-472c-44.112 0-80 35.888-80 80v272c0 44.112 35.888 80 80 80h272c44.112 0 80-35.888 80-80v-272c0-44.112-35.888-80-80-80zm277 50c-13.807 0-25 11.193-25 25 0 13.807 11.193 25 25 25 13.807 0 25-11.193 25-25 0-13.807-11.193-25-25-25zm-141 296c-71.683 0-130-58.317-130-130 7.14-172.463 252.886-172.413 260 .001 0 71.682-58.317 129.999-130 129.999zm0-220c-49.626 0-90 40.374-90 90 4.944 119.397 175.074 119.362 180-.001 0-49.625-40.374-89.999-90-89.999z"
            fill="#d3dadb"
            data-original="#000000"
          />
        </symbol>
        <symbol id="phone-call" viewBox="0 0 448 448">
          <title>phone-call</title>
          <path d="M418.72,318.72c-34.688-3.168-68.448-12-100.32-26.24c-15.712-7.008-34.048-0.64-41.728,14.592l-31.584,62.752 c-70.848-37.6-129.344-96.096-166.944-166.912l62.752-31.584c15.2-7.648,21.632-25.984,14.592-41.728 C141.248,97.728,132.416,64,129.28,29.312C127.744,12.608,114.144,0,97.6,0H16C7.168,0,0,7.168,0,16 c0,65.152,14.208,127.968,42.208,186.784c42.528,88.384,114.624,160.48,203.072,203.008C304.032,433.792,366.848,448,432,448 c8.832,0,16-7.168,16-16v-81.6C448,333.856,435.392,320.256,418.72,318.72z M32.32,32.032l65.056,0.16 c3.488,38.208,13.216,75.392,29.12,110.528l-62.144,31.328C44.96,128.832,34.208,81.216,32.32,32.032z M416,415.68 c-49.184-1.92-96.832-12.672-142.016-32l31.36-61.952C340.416,337.408,377.6,347.136,416,350.4V415.68z" />
        </symbol>
        <symbol id="down-arrow-1" viewBox="0 0 128 128">
          <title>down-arrow-1</title>
          <path
            xmlns="http://www.w3.org/2000/svg"
            id="Down_Arrow_3_"
            d="m64 88c-1.023 0-2.047-.391-2.828-1.172l-40-40c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0l37.172 37.172 37.172-37.172c1.563-1.563 4.094-1.563 5.656 0s1.563 4.094 0 5.656l-40 40c-.781.781-1.805 1.172-2.828 1.172z"
            fill="#ffffff"
            data-original="#000000"
          />
        </symbol>
        <symbol id="cancel" viewBox="0 0 520 520">
          <title>cancel</title>
          <circle cx="260" cy="43.712" r="10" />
          <circle cx="260" cy="476.288" r="10" />
          <circle cx="43.947" cy="260" r="10" />
          <circle cx="476.053" cy="260" r="10" />
          <circle cx="412.938" cy="107.062" r="10" />
          <circle cx="107.061" cy="412.939" r="10" />
          <circle cx="107.227" cy="107.227" r="10" />
          <circle cx="412.773" cy="412.773" r="10" />
          <circle cx="72.689" cy="151.856" r="10" />
          <circle cx="447.311" cy="368.144" r="10" />
          <circle cx="151.973" cy="447.108" r="10" />
          <circle cx="368.027" cy="72.893" r="10" />
          <circle cx="204.02" cy="51.082" r="10" />
          <circle cx="315.979" cy="468.918" r="10" />
          <circle cx="51.308" cy="315.919" r="10" />
          <circle cx="468.691" cy="204.082" r="10" />
          <circle cx="447.311" cy="151.856" r="10" />
          <circle cx="72.689" cy="368.144" r="10" />
          <circle cx="368.027" cy="447.108" r="10" />
          <circle cx="151.973" cy="72.893" r="10" />
          <circle cx="315.979" cy="51.082" r="10" />
          <circle cx="204.02" cy="468.918" r="10" />
          <circle cx="468.691" cy="315.919" r="10" />
          <circle cx="51.308" cy="204.082" r="10" />
          <path d="m337.067 389.216a10.093 10.093 0 0 1 -1.85-.172 9.954 9.954 0 0 1 -5.236-2.757l-69.981-69.981-69.981 69.981a10.025 10.025 0 0 1 -14.16 0l-42.15-42.15a10 10 0 0 1 0-14.143l69.991-69.994-69.991-69.989a10 10 0 0 1 0-14.143l42.16-42.159a10 10 0 0 1 14.142 0l69.989 69.991 69.989-69.989a10 10 0 0 1 14.143 0l42.159 42.159a10 10 0 0 1 0 14.143l-69.991 69.987 69.99 69.99a10 10 0 0 1 0 14.143l-42.15 42.15a9.982 9.982 0 0 1 -7.073 2.933zm-182.145-52.151 28.017 28.017 69.99-69.99a10 10 0 0 1 14.142 0l69.99 69.99 28.017-28.017-69.99-69.99a10 10 0 0 1 0-14.142l69.989-69.99-28.016-28.016-69.99 69.989a10 10 0 0 1 -14.142 0l-69.99-69.989-28.017 28.016 69.99 69.99a10 10 0 0 1 0 14.142z" />
        </symbol>
        <symbol id="check-mark" viewBox="0 0 520 520">
          <title>check-mark</title>
          <ellipse cx="261.973" cy="43.794" rx="10.087" ry="10.082" />
          <ellipse cx="261.973" cy="479.918" rx="10.087" ry="10.082" />
          <ellipse cx="44.034" cy="261.856" rx="10.087" ry="10.082" />
          <ellipse cx="479.913" cy="261.856" rx="10.087" ry="10.082" />
          <ellipse cx="416.247" cy="107.663" rx="10.087" ry="10.082" />
          <ellipse cx="107.7" cy="416.049" rx="10.087" ry="10.082" />
          <ellipse cx="107.867" cy="107.83" rx="10.087" ry="10.082" />
          <ellipse cx="416.08" cy="415.882" rx="10.087" ry="10.082" />
          <ellipse cx="73.028" cy="152.825" rx="10.087" ry="10.082" />
          <ellipse cx="450.919" cy="370.887" rx="10.087" ry="10.082" />
          <ellipse cx="153.004" cy="450.498" rx="10.087" ry="10.082" />
          <ellipse cx="370.943" cy="73.214" rx="10.087" ry="10.082" />
          <ellipse cx="205.505" cy="51.225" rx="10.087" ry="10.082" />
          <ellipse cx="318.442" cy="472.488" rx="10.087" ry="10.082" />
          <ellipse cx="51.46" cy="318.233" rx="10.087" ry="10.082" />
          <ellipse cx="472.487" cy="205.479" rx="10.087" ry="10.082" />
          <ellipse cx="450.919" cy="152.825" rx="10.087" ry="10.082" />
          <ellipse cx="73.028" cy="370.887" rx="10.087" ry="10.082" />
          <ellipse cx="370.943" cy="450.498" rx="10.087" ry="10.082" />
          <ellipse cx="153.004" cy="73.214" rx="10.087" ry="10.082" />
          <ellipse cx="318.442" cy="51.225" rx="10.087" ry="10.082" />
          <ellipse cx="205.505" cy="472.488" rx="10.087" ry="10.082" />
          <ellipse cx="472.487" cy="318.233" rx="10.087" ry="10.082" />
          <ellipse cx="51.46" cy="205.479" rx="10.087" ry="10.082" />
          <path d="m217.937 391.989h-.144a10.145 10.145 0 0 1 -7.006-2.952l-109.66-109.6a10.081 10.081 0 0 1 0-14.259l42.473-42.458a10.093 10.093 0 0 1 14.265 0l60.06 60.029 148.152-148.073a10.1 10.1 0 0 1 7.133-2.953 10.091 10.091 0 0 1 7.133 2.953l42.477 42.455a10.079 10.079 0 0 1 0 14.258l-197.75 197.647a10.091 10.091 0 0 1 -7.133 2.953zm-95.412-119.689 95.4 95.354 183.492-183.4-28.211-28.2-148.144 148.082a10.094 10.094 0 0 1 -14.266 0l-60.06-60.029z" />
        </symbol>
        <symbol id="check-box" viewBox="0 0 482.239 482.239">
          <title>check-box</title>
          <path d="m465.016 0h-447.793c-9.52 0-17.223 7.703-17.223 17.223v447.793c0 9.52 7.703 17.223 17.223 17.223h447.793c9.52 0 17.223-7.703 17.223-17.223v-447.793c0-9.52-7.703-17.223-17.223-17.223zm-17.223 447.793h-413.347v-413.347h413.348v413.347z" />
        </symbol>
        <symbol id="check-box-1" viewBox="0 0 482.239 482.239">
          <title>check-box-1</title>
          <path d="m465.016 0h-447.793c-9.52 0-17.223 7.703-17.223 17.223v447.793c0 9.52 7.703 17.223 17.223 17.223h447.793c9.52 0 17.223-7.703 17.223-17.223v-447.793c0-9.52-7.703-17.223-17.223-17.223zm-17.223 447.793h-413.347v-413.347h413.348v413.347z" />
          <path d="m373.856 184.405-24.354-24.354-142.828 142.828-73.937-73.937-24.354 24.354 98.291 98.291z" />
        </symbol>
        <symbol id="check" viewBox="0 0 512 512">
          <title>check</title>
          <path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm129.75 201.75-138.667969 138.664062c-4.160156 4.160157-9.621093 6.253907-15.082031 6.253907s-10.921875-2.09375-15.082031-6.253907l-69.332031-69.332031c-8.34375-8.339843-8.34375-21.824219 0-30.164062 8.339843-8.34375 21.820312-8.34375 30.164062 0l54.25 54.25 123.585938-123.582031c8.339843-8.34375 21.820312-8.34375 30.164062 0 8.339844 8.339843 8.339844 21.820312 0 30.164062zm0 0" />
        </symbol>
        <symbol id="remove" viewBox="0 0 512 512">
          <title>remove</title>
          <path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm94.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0" />
        </symbol>
        <symbol id="remove-1" viewBox="0 0 512 512">
          <title>remove-1</title>
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0"
            fill="#e41f30"
            data-original="#f44336"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m350.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0"
            fill="#fafafa"
            data-original="#fafafa"
          />
        </symbol>
        <symbol id="check-1" viewBox="0 0 512 512">
          <title>check-1</title>
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm129.75 201.75-138.667969 138.664062c-4.160156 4.160157-9.621093 6.253907-15.082031 6.253907s-10.921875-2.09375-15.082031-6.253907l-69.332031-69.332031c-8.34375-8.339843-8.34375-21.824219 0-30.164062 8.339843-8.34375 21.820312-8.34375 30.164062 0l54.25 54.25 123.585938-123.582031c8.339843-8.34375 21.820312-8.34375 30.164062 0 8.339844 8.339843 8.339844 21.820312 0 30.164062zm0 0"
            fill="#166c7f"
            data-original="#000000"
          />
        </symbol>
        <symbol id="download" viewBox="-10 0 410 410">
          <title>download</title>
          <path d="m109.074219 222.367188 78.707031 74c3.851562 3.621093 9.851562 3.621093 13.703125 0l78.707031-74c4.023438-3.78125 4.21875-10.109376.4375-14.132813-3.785156-4.023437-10.113281-4.21875-14.136718-.4375l-61.859376 58.15625v-255.953125c0-5.523438-4.476562-10-10-10-5.523437 0-10 4.476562-10 10v255.953125l-61.859374-58.152344c-4.023438-3.785156-10.351563-3.589843-14.136719.433594-3.78125 4.027344-3.585938 10.355469.4375 14.136719zm0 0" />
          <path d="m339.265625 74.300781h-50.707031c-5.523438 0-10 4.476563-10 10 0 5.523438 4.476562 10 10 10h50.707031c16.5625.019531 29.980469 13.4375 30 30v235.699219c-.019531 16.5625-13.4375 29.980469-30 30h-289.265625c-16.5625-.019531-29.980469-13.4375-30-30v-235.699219c.019531-16.5625 13.4375-29.980469 30-30h50.710938c5.519531 0 10-4.476562 10-10 0-5.523437-4.480469-10-10-10h-50.710938c-27.601562.03125-49.96875 22.398438-50 50v235.699219c.03125 27.601562 22.398438 49.96875 50 50h289.265625c27.601563-.03125 49.96875-22.398438 50-50v-235.699219c-.03125-27.601562-22.398437-49.96875-50-50zm0 0" />
        </symbol>
        <symbol id="share" viewBox="0 0 384 384">
          <title>share</title>
          <path d="m374 0h-99.699219c-5.523437 0-10 4.476562-10 10s4.476563 10 10 10h75.710938l-194.511719 196.375c-3.730469 3.949219-3.628906 10.148438.226562 13.972656 3.855469 3.824219 10.058594 3.867188 13.972657.105469l194.300781-196.152344v75.398438c0 5.523437 4.476562 10 10 10s10-4.476563 10-10v-99.699219c0-5.523438-4.476562-10-10-10zm0 0" />
          <path d="m324.136719 214.492188c-5.523438 0-10 4.476562-10 10v109.507812c-.019531 16.5625-13.4375 29.980469-30 30h-234.136719c-16.5625-.019531-29.980469-13.4375-30-30v-234.136719c.019531-16.5625 13.4375-29.980469 30-30h109.507812c5.523438 0 10-4.476562 10-10 0-5.523437-4.476562-10-10-10h-109.507812c-27.601562.03125-49.96875 22.398438-50 50v234.136719c.03125 27.601562 22.398438 49.96875 50 50h234.136719c27.601562-.03125 49.96875-22.398438 50-50v-109.507812c0-5.523438-4.476563-10-10-10zm0 0" />
        </symbol>
        <symbol id="email" viewBox="0 0 91 91">
          <title>email</title>
          <path d="m2.68 89.06h85.41a1.75 1.75 0 0 0 1.75-1.75v-49.19a2 2 0 0 0 -.76-1.51l-4.64-4v-22.89a1.75 1.75 0 0 0 -1.75-1.72h-27.2l-5.78-5a6.81 6.81 0 0 0 -8.64 0l-5.79 5h-27.2a1.75 1.75 0 0 0 -1.75 1.72v22.94l-4.59 3.91a1.83 1.83 0 0 0 -.81 1.55v49.19a1.75 1.75 0 0 0 1.75 1.75zm26.05-26.47-24.27 20.8v-41.49zm57.57-20.74v41.66l-24.3-20.91zm-27.62 22.53 24.64 21.18h-76l24.68-21.18zm26.69-26.38-.93.79v-1.53zm-42.03-32.3a3.36 3.36 0 0 1 4.1 0l2.66 2.3h-9.42zm-33.51 5.77h71.11v30.31l-22.34 19.1h-26.49l-22.34-19.06a1.61 1.61 0 0 0 .06-.44zm-3.5 27.42-1-.82 1-.82z" />
          <path d="m40.35 50.28a18.65 18.65 0 0 0 13.24-1.28 1.75 1.75 0 0 0 -1.59-3.12 15.4 15.4 0 0 1 -10.57 1.12c-9.59-3.67-7-13.88-6.92-14.31.77-2.9 4.56-10.26 12.9-9.63 9.37.73 9.34 9.38 9.24 11.11-.21 3.6-2.28 6.33-3.94 6.33h-.06a.94.94 0 0 1 -.73-.28 3.7 3.7 0 0 1 -.48-2.26l1.18-9.36c0-.08 0-.15 0-.22a1.75 1.75 0 0 0 -3.46-.39 6.15 6.15 0 0 0 -3.61-1.36h-.15c-3.56 0-7.67 2.83-8.42 8.18a7.4 7.4 0 0 0 5.62 8.65 7.9 7.9 0 0 0 6.21-1.59 4.69 4.69 0 0 0 .47.63 4.36 4.36 0 0 0 3.23 1.5c3.87.18 7.32-4.17 7.64-9.63.39-6.86-3.31-14.09-12.48-14.8-9.56-.74-15.14 6.91-16.54 12.23-2.84 10.76 4.4 16.66 9.22 18.48zm7.64-13a4.36 4.36 0 0 1 -4.68 2.72 3.94 3.94 0 0 1 -2.87-4.73c.54-3.79 3.29-5.17 5-5.17h.07c1.55 0 2.7 1.85 2.73 1.89a1.66 1.66 0 0 0 .4.47z" />
        </symbol>
        <symbol id="email-1" viewBox="0 0 452.84 452.84">
          <title>email-1</title>
          <path d="m449.483,190.4l.001-.001-57.824-38.335v-128.134c0-4.142-3.358-7.5-7.5-7.5h-315.49c-4.142,0-7.5,3.358-7.5,7.5v128.143l-57.814,38.326 .001,.002c-2.022,1.343-3.357,3.639-3.357,6.249v232.26c0,4.142 3.358,7.5 7.5,7.5h437.84c4.142,0 7.5-3.358 7.5-7.5v-232.26c0-2.61-1.335-4.906-3.357-6.25zm-388.313,26.229l-23.525-12.479h23.525v12.479zm-46.17-7.511l172.475,91.49-172.475,114.327v-205.817zm211.417,83.671l194.037,128.621h-388.073l194.036-128.621zm38.945,7.82l172.477-91.491v205.821l-172.477-114.33zm126.298-96.459h23.536l-23.536,12.484v-12.484zm28.794-15h-28.794v-19.09l28.794,19.09zm-43.794-157.72v193.161l-125.527,66.586-20.573-13.637c-2.511-1.665-5.776-1.665-8.287,0l-20.57,13.635-125.533-66.589v-193.156h300.49zm-315.49,157.72h-28.782l28.782-19.08v19.08z" />
          <path d="m226.415,213.671h59.754c4.142,0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-59.754c-28.813,0-52.254-23.441-52.254-52.254v-2.213c0-28.813 23.441-52.254 52.254-52.254s52.254,23.441 52.254,52.254v5.533c0,6.237-5.074,11.312-11.312,11.312s-11.312-5.074-11.312-11.312v-10.512c0-17.864-14.533-32.398-32.397-32.398s-32.397,14.533-32.397,32.398c0,17.864 14.533,32.397 32.397,32.397 8.169,0 15.636-3.045 21.34-8.052 4.644,7.483 12.932,12.478 22.369,12.478 14.508,0 26.312-11.803 26.312-26.312v-5.533c0-37.084-30.17-67.254-67.254-67.254s-67.254,30.17-67.254,67.254v2.213c5.68434e-14,37.085 30.17,67.255 67.254,67.255zm-2.767-57.049c-9.593,0-17.397-7.804-17.397-17.397s7.805-17.398 17.397-17.398 17.397,7.805 17.397,17.398-7.804,17.397-17.397,17.397z" />
        </symbol>
        <symbol id="person" viewBox="0 0 64 64">
          <title>person</title>
          <path d="m43.5 18.5c0 3.1-1.26 6.01-3.56 8.19-.46.43-.95.83-1.47 1.19-1.96 1.34-4.3 2.09-6.64 2.09-1.77 0-3.48-.42-5.07-1.23-.45-.23-.89-.5-1.3-.79-2.9-2.03-4.79-5.4-4.95-8.94-.18-4.14 1.89-8.08 5.41-10.28 1.81-1.12 3.91-1.72 6.09-1.72 2 0 3.95.51 5.65 1.48 3.6 2.04 5.84 5.87 5.84 10.01z" />
          <path d="m55 43.51v6.96c0 1.26-.79 2.39-1.97 2.82-13.56 4.93-28.5 4.93-42.06 0-1.18-.43-1.97-1.56-1.97-2.82v-6.96c0-3.77 1.89-7.23 5.07-9.26 3.26-2.1 6.82-3.59 10.6-4.43.38.25.77.48 1.18.69 1.88.97 3.89 1.46 5.98 1.46 1.73 0 3.46-.35 5.08-1 .79-.32 1.55-.72 2.28-1.18 3.83.84 7.44 2.34 10.74 4.46 3.18 2.03 5.07 5.49 5.07 9.26z" />
        </symbol>
        <symbol id="woman" viewBox="0 0 512 512">
          <title>woman</title>
          <path d="M421.184,384.96l-81.728-20.437l-4.715-18.88c56.384-9.493,85.397-26.389,86.677-27.136 c3.029-1.771,4.907-4.992,5.163-8.491c0.235-3.499-1.323-6.933-4.075-9.131C422.123,300.587,384,269.099,384,160 c0-92.011-21.525-138.667-64-138.667h-6.293C298.389,6.528,286.891,0,256,0c-40.085,0-128,40.299-128,160 c0,109.099-38.123,140.587-38.4,140.8c-2.837,2.133-4.437,5.525-4.267,9.067c0.192,3.563,2.112,6.763,5.163,8.597 c1.28,0.768,30.016,17.749,86.741,27.221l-4.715,18.837L90.795,384.96C37.333,398.336,0,446.165,0,501.333 C0,507.221,4.779,512,10.667,512h490.667c5.888,0,10.667-4.821,10.667-10.709C512,446.165,474.667,398.336,421.184,384.96z" />
        </symbol>
        <symbol id="avatar" viewBox="0 0 512 512">
          <title>avatar</title>
          <path d="M421.184,384.96l-81.728-20.437l-6.955-27.819c21.867-21.653,36.843-51.392,41.28-81.899 c12.117-3.413,21.504-13.803,23.125-26.837l5.333-42.667c1.131-9.003-1.643-18.112-7.616-24.939 c-3.499-4.011-7.915-7.04-12.8-8.896l1.963-40.171l7.979-8c12.011-12.779,21.973-34.688,1.152-66.475 C376.917,12.395,349.739,0,312.107,0c-14.848,0-49.024,0-80.853,21.376C140.373,23.275,128,65.493,128,106.667 c0,9.579,2.325,31.147,3.861,44.16c-5.483,1.728-10.453,4.928-14.336,9.344c-6.08,6.891-8.896,16.043-7.765,25.152l5.333,42.667 c1.749,13.909,12.309,24.853,25.664,27.456c4.416,29.333,18.624,58.112,39.232,79.403l-7.424,29.717l-81.728,20.437 C37.333,398.336,0,446.165,0,501.333C0,507.221,4.779,512,10.667,512h490.667c5.888,0,10.667-4.821,10.667-10.709 C512,446.165,474.667,398.336,421.184,384.96z" />
        </symbol>
      </svg>
    </div>
  )
}

export default IconSvgSprite
