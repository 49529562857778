import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import {
  SetInitialTravelFilterResponseFulfilledPayload,
  SetTravelFilterQueryPayload,
  SetTravelFilterSelectionPayload,
  TravelFilterResponsePayload,
  TravelFilterState,
} from '@/features/travelFilter/types'
import {
  executeTravelFilterRequest,
  resetTravelFilter,
  setTravelFilterQuery,
  setTravelFilterSelection,
  setInitialTravelFilterResponse,
  applyTravelFilterUrlQuery,
  initializeTravelFilter,
} from '@/features/travelFilter/actions'
import { setTravelFilterStateResponse, toggleUpdateUrlTrigger } from '@/features/travelFilter/helpers'

const initialState: TravelFilterState = {
  initialized: false,
  updateUrlTrigger: true,
  filters: [
    {
      name: 'destinations',
      options: [],
    },
    {
      name: 'travelTypes',
      options: [],
    },
    {
      name: 'months',
      options: [],
    },
  ],
  travels: [],
  counts: { total: 0, filteredTotal: 0 },
  query: '',
  filterSelections: {
    destinations: [],
    travelTypes: [],
    months: [],
  },
}

export const travelFilterReducer = createReducer(initialState, (builder) => {
  builder.addCase(initializeTravelFilter.fulfilled, (state, { payload }: PayloadAction<TravelFilterResponsePayload>) => {
    state.initialized = true
    setTravelFilterStateResponse(state, payload.filterResponse)
  })
  builder.addCase(setTravelFilterQuery, (state, { payload }: PayloadAction<SetTravelFilterQueryPayload>) => {
    state.query = payload.query ? payload.query : ''
  })
  builder.addCase(setTravelFilterSelection, (state, { payload }: PayloadAction<SetTravelFilterSelectionPayload>) => {
    state.filterSelections[payload.name] = payload.selection
  })
  builder.addCase(resetTravelFilter.fulfilled, (state, { payload }: PayloadAction<TravelFilterResponsePayload>) => {
    // reset filters
    toggleUpdateUrlTrigger(state)
    state.query = ''
    state.filterSelections = {
      destinations: [],
      travelTypes: [],
      months: [],
    }

    setTravelFilterStateResponse(state, payload.filterResponse)
  })
  builder.addCase(setInitialTravelFilterResponse, (state, { payload }: PayloadAction<TravelFilterResponsePayload>) => {
    state.initialized = true
    setTravelFilterStateResponse(state, payload.filterResponse)
    state.query = ''
    state.filterSelections = {
      destinations: [],
      travelTypes: [],
      months: [],
    }
  })
  // builder.addCase(setInitialTravelFilterResponse.fulfilled, (state, { payload }: PayloadAction<SetInitialTravelFilterResponseFulfilledPayload>) => {
  //   state.filterSelections = payload.filterSelections
  //   state.query = payload.query
  //   setTravelFilterStateResponse(state, payload.filterResponse)
  // })
  // builder.addCase(applyTravelFilterUrlQuery.pending, (state, action) => {
  //   // setTravelFilterStateResponse(state, action.meta.arg.filterResponse)
  // })
  builder.addCase(applyTravelFilterUrlQuery.fulfilled, (state, { payload }: PayloadAction<SetInitialTravelFilterResponseFulfilledPayload>) => {
    // state.initialized = true
    toggleUpdateUrlTrigger(state)
    state.filterSelections = payload.filterSelections
    state.query = payload.query
    setTravelFilterStateResponse(state, payload.filterResponse)
  })
  // builder.addCase(executeTravelFilterRequest.pending, (state, action) => {})
  builder.addCase(executeTravelFilterRequest.fulfilled, (state, { payload }: PayloadAction<TravelFilterResponsePayload>) => {
    toggleUpdateUrlTrigger(state)
    setTravelFilterStateResponse(state, payload.filterResponse)
  })
})

export default travelFilterReducer
