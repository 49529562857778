import { GraphQLClient } from 'graphql-request'

const url = typeof window === 'undefined' ? (process.env.API_URL as string) : (process.env.NEXT_PUBLIC_API_PROXY_URL as string)

const graphQLClient = new GraphQLClient(url, {
  mode: 'cors',
})
// graphQLClient.setHeaders({
//   'skag-tenant-id': process.env.NEXT_PUBLIC_TENANT_ID as string,
// })
export default graphQLClient
