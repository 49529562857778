import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { BestsellersState, SetBestsellersFulfilledPayload } from '@/features/bestsellers/types'
import { loadBestsellers } from '@/features/bestsellers/actions'
import { toDbDate } from '@/lib/helpers/helpers'

const initialState: BestsellersState = {
  isLoading: false,
  timestamp: 0,
  travels: [],
}

export const bestsellersReducer = createReducer(initialState, (builder) => {
  builder.addCase(loadBestsellers.pending, (state) => {
    state.isLoading = true
  })
  builder.addCase(loadBestsellers.fulfilled, (state, { payload }: PayloadAction<SetBestsellersFulfilledPayload>) => {
    state.isLoading = false
    state.timestamp = Math.round(new Date(toDbDate(new Date())).getTime() / 1000)
    state.travels = payload.travels
  })
})

export default bestsellersReducer
