import { deepmerge } from '@mui/utils'
import { createTheme, ThemeOptions } from '@mui/material/styles'
const themeOptions: ThemeOptions = await import(`themes/${process.env.NEXT_PUBLIC_THEME}/styles/muiThemeOptions`).then((object) => object.default)

const defaultOptions = {
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          // border: '1px solid #ced4da',
          backgroundColor: '#fbfbfb',
          width: '100%',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
        input: {
          backgroundColor: '#fbfbfb',
          padding: '0.625rem 0.8rem !important',
          lineHeight: '1.3',
          fontSize: '1rem',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: '-7px',
          '&.Mui-error': {
            color: 'inherit',
          },
        },
        shrink: {
          top: '0',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
        notchedOutline: {
          top: '-5px',
          bottom: '1px',
          borderRadius: 0,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '0.875em',
          margin: 0,
          position: 'absolute',
          top: '100%',
          marginTop: '-4px',
          width: 'auto',
          right: 0,
        },
      },
    },
  },
} as ThemeOptions
const theme = createTheme(deepmerge(defaultOptions, themeOptions))

export default theme
