import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { loadState } from '@/app/browserStorage'
import bookingReducer from '@/features/booking/reducer'
import travelFilterReducer from '@/features/travelFilter/reducer'
import bestsellersReducer from '@/features/bestsellers/reducer'

export const store = configureStore({
  reducer: {
    bookings: bookingReducer,
    travelFilter: travelFilterReducer,
    bestsellers: bestsellersReducer,
  },
  preloadedState: loadState(),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
