import { createAsyncThunk } from '@reduxjs/toolkit'
import graphQLClient from '@/lib/api/graphQLClient'
import { BESTSELLERS_QUERY } from '@/features/bestsellers/gql'
import { toDbDate } from '@/lib/helpers/helpers'
import { SetBestsellersFulfilledPayload } from '@/features/bestsellers/types'

export const loadBestsellers = createAsyncThunk(
  'bestsellers/loadBestsellers',
  async (payload: any, thunkAPI): Promise<SetBestsellersFulfilledPayload> => {
    // @ts-ignore
    const state = thunkAPI.getState().bestsellers
    const timestamp = state.timestamp
    const today = Math.round(new Date(toDbDate(new Date())).getTime() / 1000)

    let travels = state.travels
    // reload if data is older than today
    if (today !== timestamp) {
      const response = await graphQLClient.request(BESTSELLERS_QUERY, { tenantId: process.env.NEXT_PUBLIC_TENANT_ID })
      travels = response.bestsellerTravels
    }
    return {
      travels: travels,
    }
  }
)
