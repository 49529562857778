import { createReducer, current, PayloadAction } from '@reduxjs/toolkit'
import {
  applyMandatoryBookingAddons,
  downgradeBookingFromExtensionDate,
  initBooking,
  loadBookingSummary,
  loadContingents,
  // loadExtensionContingents,
  reloadPricingTravel,
  resetBookingState,
  setAccommodationAddonSelection,
  setAccommodationSelection,
  setBabies,
  setBabyAddress,
  setBillingAddress,
  setBookingAddonSelection,
  setBookingFatalError,
  setBookingLastActivityTimestamp,
  setBookingRemarks,
  setBusStopSelectionOutbound,
  setChildren,
  setCollectiveAddonSelection,
  setCurrentBookingStep,
  setFamilyInsurance,
  setFamilyPackage,
  setMarketingInformation,
  setPaymentMethod,
  setPersonalAddonSelection,
  setPricingTravelId,
  setTravelDate,
  setTravelDocumentationService,
  setTravellerAddress,
  setTravellerInsurance,
  setTravellerRemarks,
  setTravellers,
  submitBooking,
  switchBookingExtensionDate,
  upgradeBookingToExtensionDate,
  setBusStopSelectionReturn,
  toggleWaitlistFallbackAvailabilityStatus,
  setBookingCustomData,
  setBookingEntryPoint,
  setPersonalDiscountSelection,
  initBookingPayment,
  setCO2Compensation,
  setBookingEsimService,
} from './actions'
import {
  applyMandatoryBookingAddonsPayload,
  bookingsState,
  bookingStateItem,
  contingentsPayload,
  downgradeBookingFromExtensionDatePayload,
  initBookingFulfilledPayload,
  loadBookingSummaryResponsePayload,
  setAccommodationAddonSelectionPayload,
  setAccommodationSelectionPayload,
  setBabiesPayload,
  setBabyAddressPayload,
  setBillingAddressPayload,
  setBookingAddonSelectionPayload,
  setBookingRemarksPayload,
  setChildrenPayload,
  setCollectiveAddonSelectionPayload,
  setFamilyInsurancePayload,
  setFamilyPackagePayload,
  setMarketingInformationPayload,
  setPaymentMethodPayload,
  setPersonalAddonSelectionPayload,
  setPricingTravelPayload,
  setTravelDatePayload,
  setTravelDocumentationServicePayload,
  setTravellerAddressPayload,
  setTravellerInsurancePayload,
  setTravellerRemarksPayload,
  setTravellersPayload,
  switchBookingExtensionDatePayload,
  upgradeBookingToExtensionDatePayload,
  submitBookingResponsePayload,
  setBookingFatalErrorPayload,
  resetBookingStatePayload,
  setBookingLastActivityTimestampPayload,
  setReloadedPricingTravelPayload,
  setCurrentBookingStepPayload,
  setBusStopSelectionPayload,
  ToggleWaitlistFallbackAvailabilityStatusPayload,
  setBookingCustomDataPayload,
  setBookingEntryPointPayload,
  setPersonalDiscountSelectionPayload,
  initBookingPaymentFulfilledPayload,
  setCO2CompensationPayload,
  setBookingEsimServicePayload,
  // extensionContingentsPayload,
} from '@/features/booking/types'
import {
  applyAccommodationAddonSelection,
  applyBookingAddonSelection,
  applyCollectiveAddonSelection,
  sanitizeAddonsWithRules,
  applyPersonalAddonSelection,
  applyMandatoryPersonalDiscounts,
  applyTravelDate,
  buildBabyTravellers,
  buildTravellers,
  buildTravellersFromChildrenData,
  downgradeFromExtensionDate,
  getAccommodationCapacityCount,
  isAccommodationApplicableToTraveller,
  orderTravellers,
  removeBabyTravellers,
  removeChildTravellers,
  removeTravellerServices,
  resetBooking,
  sanitizeAccommodationAddons,
  sanitizeFamilyPackageAddons,
  sanitizeInsuranceSelection,
  sanitizePricingTravel,
  sendBookingFunnelEvent,
  setBabyData,
  setBillingAddressData,
  setTravellerData,
  travellerHasAccommodation,
  unsetFamilyPackage,
  unsetTravellerAccommodations,
  upgradeToExtensionDate,
  applyMandatoryAddons,
  applyPersonalDiscountSelection,
  sanitizeDiscountsWithRules,
  applyBargainDiscount,
  handleBillingAddressMinAge,
  updateEsimServiceSelection,
  determineAndSetAvailabilityStatus,
} from '@/features/booking/helpers'
import ratioTravelDateObject from '@/entities/ratioTravelDate/interface'
import travelPricingAgeGroupObject from '@/entities/travelPricingAgeGroup/interface'
import tenantOnlinePaymentConfigObject from '@/entities/tenantOnlinePaymentConfig/interface'

let initialState: bookingsState = []
export const bookingReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setBookingEntryPoint, (state, { payload }: PayloadAction<setBookingEntryPointPayload>) => {
      const booking = getBooking(state, payload.travelId)
      booking.entryPoint = payload.entryPoint
    })
    .addCase(initBooking.fulfilled, (state, { payload }: PayloadAction<initBookingFulfilledPayload>) => {
      const travel = payload.travel
      const booking = getBooking(state, travel.id as number)

      // create booking
      if (!booking) {
        createBooking(state, {
          version: process.env.NEXT_PUBLIC_BOOKING_VERSION as string,
          entryPoint: 'travelPage',
          currentStep: -1,
          sentEvents: [],
          lastActivityTimestamp: Date.now(),
          travelId: travel.id as number,
          travel: travel,
          switchedToWaitlistFallback: false,
          hasTransportTypes: payload.hasTransportTypes,
          ageGroups: travel.pricing.ageGroupSettings.ageGroups,
          travellers: [],
          babies: [],
          travellerRemarks: '',
          billingAddressForcedDueToMinorAge: false,
          billingAddress: {
            salutation: 'MR',
            firstName: '',
            lastName: '',
            birthday: '',
            email: '',
            phone: '',
            address: '',
            addressAddition: '',
            zip: '',
            city: '',
            country: '',
          },
          accommodationSelection: [],
          accommodationAddonSelection: [],
          personalAddonSelection: [],
          bargainDiscountSelection: [],
          bookingAddonSelection: [],
          collectiveAddonSelection: [],
          personalDiscountSelection: [],
          isUpgradedToExtension: false,
          ratioSummary: undefined,
          travelDocumentationService: 'electronic',
          customData: {},
          payment: {
            type: 'invoice',
          },
          co2compensation: false,
          newsletterRegistration: false,
          gtcAccepted: false,
          bookingRemarks: '',
          acquisitionChannel: '',
          bargainConfig: payload.bargainConfig,
          esimConfig: payload.esimConfig,
          onlinePaymentConfig: payload.onlinePaymentConfig,
        })
      } else {
        booking.travel = travel
        booking.bargainConfig = payload.bargainConfig
        booking.esimConfig = payload.esimConfig
        booking.ageGroups = travel.pricing.ageGroupSettings.ageGroups
        booking.hasTransportTypes = payload.hasTransportTypes
        booking.onlinePaymentConfig = payload.onlinePaymentConfig
      }
      if (booking.travel?.pricing) {
        // booking.travel.pricing.travels = sanitizePricingTravels(travel.pricing.travels)
      }
      if (payload.isReloaded) {
        booking.travelTimestamp = Date.now()

        // set availability status
        booking.defaultAvailabilityStatus = payload.defaultAvailabilityStatus
        booking.defaultWaitlistFallbackAvailabilityStatus = payload.defaultWaitlistFallbackAvailabilityStatus
        if (!booking.switchedToWaitlistFallback) {
          determineAndSetAvailabilityStatus(booking)
          // booking.availabilityStatus = travel.availabilityStatus ? travel.availabilityStatus : payload.defaultAvailabilityStatus
        }
      }
    })
    .addCase(setPricingTravelId.fulfilled, (state, { payload }: PayloadAction<setPricingTravelPayload>) => {
      const booking = getBooking(state, payload.travelId)
      // const pricingTravel = sanitizePricingTravel(payload.pricingTravel)
      const pricingTravel = payload.isReloaded ? sanitizePricingTravel(payload.pricingTravel) : payload.pricingTravel

      // reset travelDate
      if (payload.pricingTravel && payload.pricingTravel.id !== booking.pricingTravel?.id) {
        applyTravelDate(booking, pricingTravel.dates.length ? pricingTravel.dates[0] : undefined)
      }

      resetBooking(booking)
      booking.pricingTravelTimestamp = Date.now()
      booking.pricingTravel = pricingTravel
    })
    .addCase(reloadPricingTravel.fulfilled, (state, { payload }: PayloadAction<setReloadedPricingTravelPayload>) => {
      if (payload.pricingTravel) {
        const booking = getBooking(state, payload.travelId)

        // update travelDate (contains prices)
        if (booking.travelDate) {
          applyTravelDate(
            booking,
            payload.pricingTravel.dates.find((date) => {
              // @ts-ignore
              return date.id === booking.travelDate.id
            })
          )
        }
        if (payload.isReloaded) {
          booking.pricingTravelTimestamp = Date.now()
          // payload.pricingTravel = sanitizePricingTravel(payload.pricingTravel)
        }
        booking.pricingTravel = payload.pricingTravel
      }
    })
    .addCase(toggleWaitlistFallbackAvailabilityStatus, (state, { payload }: PayloadAction<ToggleWaitlistFallbackAvailabilityStatusPayload>) => {
      const booking = getBooking(state, payload.travelId)
      if (booking.switchedToWaitlistFallback) {
        removeTravellerServices(booking)
      }

      // set availabilityStatus
      if (!booking.switchedToWaitlistFallback) {
        // set waitlistFallback status
        booking.availabilityStatus = booking.defaultWaitlistFallbackAvailabilityStatus
      } else {
        // set initial status
        determineAndSetAvailabilityStatus(booking)
        // booking.availabilityStatus = booking.travel?.availabilityStatus ? booking.travel.availabilityStatus : booking.defaultAvailabilityStatus
      }

      booking.switchedToWaitlistFallback = !booking.switchedToWaitlistFallback
    })
    .addCase(setTravelDate, (state, { payload }: PayloadAction<setTravelDatePayload>) => {
      const booking = getBooking(state, payload.travelId)
      resetBooking(booking)
      applyTravelDate(booking, payload.date)
    })
    .addCase(setTravellers, (state, { payload }: PayloadAction<setTravellersPayload>) => {
      const booking = getBooking(state, payload.travelId)

      // reset travellers
      removeTravellerServices(booking)

      // build travellers
      const travellers = buildTravellers(payload.travellers)

      // order and set
      booking.travellers = orderTravellers(travellers, current(booking.ageGroups) as travelPricingAgeGroupObject[])
    })
    .addCase(setChildren, (state, { payload }: PayloadAction<setChildrenPayload>) => {
      const booking = getBooking(state, payload.travelId)

      // remove current
      removeChildTravellers(booking)

      // reset travellers
      removeTravellerServices(booking)

      // create new
      const travellers = buildTravellersFromChildrenData(
        payload.children,
        current(booking.ageGroups) as travelPricingAgeGroupObject[],
        current(booking.travelDate) as ratioTravelDateObject
      )

      // order and set
      booking.travellers = orderTravellers([...booking.travellers, ...travellers], current(booking.ageGroups) as travelPricingAgeGroupObject[])
    })
    .addCase(setBabies, (state, { payload }: PayloadAction<setBabiesPayload>) => {
      const booking = getBooking(state, payload.travelId)

      // remove current
      removeBabyTravellers(booking)

      booking.babies = buildBabyTravellers(payload.babies, booking.pricingTravel ? booking.pricingTravel.accommodations : [])
    })
    .addCase(setTravellerAddress, (state, { payload }: PayloadAction<setTravellerAddressPayload>) => {
      const booking = getBooking(state, payload.travelId)

      // sanitize isBillingAddress
      if (payload.values.isBillingAddress) {
        booking.travellers.forEach((traveller) => {
          traveller.isBillingAddress = false
        })
      }

      const traveller = booking.travellers[payload.travellerIndex]
      setTravellerData(traveller, payload.values)
      handleBillingAddressMinAge(booking)
    })
    .addCase(setBabyAddress, (state, { payload }: PayloadAction<setBabyAddressPayload>) => {
      const booking = getBooking(state, payload.travelId)

      const baby = booking.babies[payload.babyIndex]
      setBabyData(baby, payload.values)
    })
    .addCase(setBillingAddress, (state, { payload }: PayloadAction<setBillingAddressPayload>) => {
      const booking = getBooking(state, payload.travelId)
      setBillingAddressData(booking, payload.values)
    })
    .addCase(setTravellerRemarks, (state, { payload }: PayloadAction<setTravellerRemarksPayload>) => {
      const booking = getBooking(state, payload.travelId)
      booking.travellerRemarks = payload.remarks
    })
    .addCase(setBookingRemarks, (state, { payload }: PayloadAction<setBookingRemarksPayload>) => {
      const booking = getBooking(state, payload.travelId)
      booking.bookingRemarks = payload.remarks
    })
    .addCase(loadContingents.fulfilled, (state, { payload }: PayloadAction<contingentsPayload>) => {
      if (payload.contingents) {
        const booking = getBooking(state, payload.travelId)
        booking.contingentTimestamp = Date.now()
        booking.contingents = payload.contingents
      }
    })
    // .addCase(loadExtensionContingents.fulfilled, (state, { payload }: PayloadAction<extensionContingentsPayload>) => {
    //   console.log('payload: ', payload)
    //   if (payload.contingents) {
    //     const booking = getBooking(state, payload.travelId)
    //     booking.extensionContingentsTimestamp = Date.now()
    //     booking.extensionContingents = payload.contingents
    //   }
    // })
    .addCase(setFamilyPackage, (state, { payload }: PayloadAction<setFamilyPackagePayload>) => {
      const booking = getBooking(state, payload.travelId)

      // reset
      unsetFamilyPackage(booking)

      if (payload.familyPackage) {
        // assign familyPackage (Service) to first traveller
        booking.travellers[0].familyPackage = payload.familyPackage

        // assign accommodation (Package) to all travellers without accommodation
        const accommodation = payload.familyPackage.accommodation
        booking.travellers.forEach((traveller) => {
          if (!traveller.accommodation) {
            traveller.accommodation = accommodation
            traveller.isFamilyPackageMember = true
          }
        })
      }

      // sanitize addons
      sanitizeFamilyPackageAddons(booking, payload.familyPackage)
      applyMandatoryAddons(booking)
      sanitizeAddonsWithRules(booking)
      booking.ratioSummary = undefined
    })
    .addCase(setAccommodationSelection, (state, { payload }: PayloadAction<setAccommodationSelectionPayload>) => {
      const booking = getBooking(state, payload.travelId)

      // reset
      unsetTravellerAccommodations(booking)

      booking.accommodationSelection = payload.selection
      // add given quantity of accommodation to next travellers without accommodation
      payload.selection.forEach((item) => {
        /**
         * calc number of people
         *
         * default: per room (quantity * capacity)
         * partial occupancy: per person/bed (quantity)
         */
        let openQuantity = item.accommodation.capacitySettings.partialOccupancyAllowed
          ? item.quantity
          : item.quantity * getAccommodationCapacityCount(item.accommodation.capacitySettings)

        booking.travellers.forEach((traveller) => {
          if (openQuantity > 0) {
            // add if traveller has no accommodation
            if (!travellerHasAccommodation(traveller) && isAccommodationApplicableToTraveller(item.accommodation, traveller)) {
              traveller.accommodation = item.accommodation
              openQuantity--
            }
          }
        })
      })

      // sanitize addons
      sanitizeAccommodationAddons(booking)
      applyMandatoryAddons(booking)
      sanitizeAddonsWithRules(booking)
      applyMandatoryPersonalDiscounts(booking)
      sanitizeDiscountsWithRules(booking)
      booking.ratioSummary = undefined
    })
    .addCase(setAccommodationAddonSelection, (state, { payload }: PayloadAction<setAccommodationAddonSelectionPayload>) => {
      const booking = getBooking(state, payload.travelId)
      booking.accommodationAddonSelection = payload.selection
      applyAccommodationAddonSelection(booking)
      booking.ratioSummary = undefined
    })
    .addCase(setPersonalAddonSelection, (state, { payload }: PayloadAction<setPersonalAddonSelectionPayload>) => {
      const booking = getBooking(state, payload.travelId)
      booking.personalAddonSelection = payload.selection
      applyPersonalAddonSelection(booking)
      booking.ratioSummary = undefined
    })
    .addCase(setBookingAddonSelection, (state, { payload }: PayloadAction<setBookingAddonSelectionPayload>) => {
      const booking = getBooking(state, payload.travelId)
      booking.bookingAddonSelection = payload.selection
      applyBookingAddonSelection(booking)
      booking.ratioSummary = undefined
    })
    .addCase(setCollectiveAddonSelection, (state, { payload }: PayloadAction<setCollectiveAddonSelectionPayload>) => {
      const booking = getBooking(state, payload.travelId)
      booking.collectiveAddonSelection = payload.selection
      applyCollectiveAddonSelection(booking)
      booking.ratioSummary = undefined
    })
    .addCase(applyMandatoryBookingAddons, (state, { payload }: PayloadAction<applyMandatoryBookingAddonsPayload>) => {
      const booking = getBooking(state, payload.travelId)
      applyMandatoryAddons(booking)
      sanitizeAddonsWithRules(booking)
      applyMandatoryPersonalDiscounts(booking)
      applyBargainDiscount(booking)
      if (booking.currentStep < 2) {
        booking.ratioSummary = undefined
      }
    })
    .addCase(setPersonalDiscountSelection, (state, { payload }: PayloadAction<setPersonalDiscountSelectionPayload>) => {
      const booking = getBooking(state, payload.travelId)
      booking.personalDiscountSelection = payload.selection
      applyPersonalDiscountSelection(booking)
      booking.ratioSummary = undefined
    })
    .addCase(upgradeBookingToExtensionDate, (state, { payload }: PayloadAction<upgradeBookingToExtensionDatePayload>) => {
      const booking = getBooking(state, payload.travelId)
      upgradeToExtensionDate(booking, payload.extensionDate)
      booking.ratioSummary = undefined
    })
    .addCase(switchBookingExtensionDate, (state, { payload }: PayloadAction<switchBookingExtensionDatePayload>) => {
      const booking = getBooking(state, payload.travelId)
      downgradeFromExtensionDate(booking)
      upgradeToExtensionDate(booking, payload.extensionDate)
      booking.ratioSummary = undefined
    })
    .addCase(downgradeBookingFromExtensionDate, (state, { payload }: PayloadAction<downgradeBookingFromExtensionDatePayload>) => {
      const booking = getBooking(state, payload.travelId)
      downgradeFromExtensionDate(booking)
      booking.ratioSummary = undefined
    })
    .addCase(loadBookingSummary.pending, (state, action) => {
      const booking = getBooking(state, action.meta.arg.travelId)
      booking.ratioSummary = undefined
      booking.ratioSummaryIsLoading = true
    })
    .addCase(loadBookingSummary.rejected, (state, action) => {
      const booking = getBooking(state, action.meta.arg.travelId)
      booking.ratioSummaryIsLoading = undefined
      if (!booking.ratioSummaryLoadingError) {
        booking.ratioSummaryLoadingError = 1
      } else {
        booking.ratioSummaryLoadingError = booking.ratioSummaryLoadingError + 1
      }
    })
    .addCase(loadBookingSummary.fulfilled, (state, { payload }: PayloadAction<loadBookingSummaryResponsePayload>) => {
      const booking = getBooking(state, payload.travelId)
      booking.ratioSummaryLoadingError = undefined
      booking.ratioSummaryIsLoading = undefined
      booking.ratioSummary = payload.summary
      sanitizeInsuranceSelection(booking)
    })
    .addCase(setFamilyInsurance, (state, { payload }: PayloadAction<setFamilyInsurancePayload>) => {
      const booking = getBooking(state, payload.travelId)

      // reset personal insurances
      booking.travellers.forEach((traveller) => {
        traveller.insurance = undefined
      })

      // set family insurance
      booking.familyInsuranceSelection = payload.insurance
    })
    .addCase(setTravellerInsurance, (state, { payload }: PayloadAction<setTravellerInsurancePayload>) => {
      const booking = getBooking(state, payload.travelId)

      // reset family insurance
      booking.familyInsuranceSelection = undefined

      // set personal insurance
      booking.travellers[payload.travellerIndex].insurance = payload.insurance
    })
    .addCase(setBusStopSelectionOutbound, (state, { payload }: PayloadAction<setBusStopSelectionPayload>) => {
      const booking = getBooking(state, payload.travelId)
      if (payload.travellerIndex === undefined) {
        booking.travellers.forEach((traveller) => {
          traveller.busStopItemOutbound = payload.busStopItem
        })
      } else {
        booking.travellers[payload.travellerIndex].busStopItemOutbound = payload.busStopItem
      }
    })
    .addCase(setBusStopSelectionReturn, (state, { payload }: PayloadAction<setBusStopSelectionPayload>) => {
      const booking = getBooking(state, payload.travelId)
      if (payload.travellerIndex === undefined) {
        booking.travellers.forEach((traveller) => {
          traveller.busStopItemReturn = payload.busStopItem
        })
      } else {
        booking.travellers[payload.travellerIndex].busStopItemReturn = payload.busStopItem
      }
    })
    .addCase(setBookingEsimService, (state, { payload }: PayloadAction<setBookingEsimServicePayload>) => {
      const booking = getBooking(state, payload.travelId)
      updateEsimServiceSelection(booking, payload.service, payload.quantity)
    })
    .addCase(setTravelDocumentationService, (state, { payload }: PayloadAction<setTravelDocumentationServicePayload>) => {
      const booking = getBooking(state, payload.travelId)
      booking.travelDocumentationService = payload.serviceName
    })
    .addCase(setBookingCustomData, (state, { payload }: PayloadAction<setBookingCustomDataPayload>) => {
      const booking = getBooking(state, payload.travelId)
      booking.customData = payload.data
    })
    .addCase(setPaymentMethod, (state, { payload }: PayloadAction<setPaymentMethodPayload>) => {
      const booking = getBooking(state, payload.travelId)
      booking.payment.type = payload.type
    })
    .addCase(setCO2Compensation, (state, { payload }: PayloadAction<setCO2CompensationPayload>) => {
      const booking = getBooking(state, payload.travelId)
      booking.co2compensation = payload.selected
      applyPersonalAddonSelection(booking)
      applyMandatoryAddons(booking)
    })
    .addCase(setMarketingInformation, (state, { payload }: PayloadAction<setMarketingInformationPayload>) => {
      const booking = getBooking(state, payload.travelId)
      booking.acquisitionChannel = payload.acquisitionChannel
      booking.bookingRemarks = payload.bookingRemarks
      booking.newsletterRegistration = payload.newsletterRegistration
      booking.gtcAccepted = payload.gtcAccepted

      // reset submit (dev)
      booking.submitSuccessful = undefined
      booking.isSubmitting = undefined
    })
    .addCase(submitBooking.pending, (state, action) => {
      const booking = getBooking(state, action.meta.arg.travelId)
      booking.isSubmitting = true
    })
    .addCase(submitBooking.fulfilled, (state, { payload }: PayloadAction<submitBookingResponsePayload>) => {
      const booking = getBooking(state, payload.travelId)
      booking.submitSuccessful = payload.success
      booking.bookingId = payload.bookingId
      booking.orderNo = payload.orderNo
      booking.isSubmitting = undefined
    })

    // payment
    .addCase(initBookingPayment.pending, (state, action) => {
      const booking = getBooking(state, action.meta.arg.travelId)
      booking.payment.initialized = false
      booking.payment.initializing = true
    })
    .addCase(initBookingPayment.fulfilled, (state, { payload }: PayloadAction<initBookingPaymentFulfilledPayload>) => {
      const booking = getBooking(state, payload.travelId)
      booking.payment.initializing = undefined
      booking.payment.initialized = true
      booking.payment.transactionId = payload.transactionId
    })

    // error handling
    .addCase(setBookingFatalError, (state, { payload }: PayloadAction<setBookingFatalErrorPayload>) => {
      const booking = getBooking(state, payload.travelId)
      booking.fatalError = true
    })
    .addCase(resetBookingState, (state, {}: PayloadAction<resetBookingStatePayload>) => {
      state.splice(0, 999)
    })
    .addCase(setBookingLastActivityTimestamp, (state, { payload }: PayloadAction<setBookingLastActivityTimestampPayload>) => {
      const booking = getBooking(state, payload.travelId)
      booking.lastActivityTimestamp = Date.now()
    })
    .addCase(setCurrentBookingStep, (state, { payload }: PayloadAction<setCurrentBookingStepPayload>) => {
      const booking = getBooking(state, payload.travelId)
      booking.currentStep = payload.step
      booking.lastActivityTimestamp = Date.now()
      sendBookingFunnelEvent(booking, payload.step)
    })
})

const createBooking = (state: bookingsState, data: bookingStateItem) => {
  state.push(data)
}

const getBooking = (state: bookingsState, travelId: number): bookingStateItem => {
  let item = state.find((booking) => {
    return booking.travelId === travelId && booking.version === process.env.NEXT_PUBLIC_BOOKING_VERSION
  })

  if (!item) {
    createBooking(state, {
      version: process.env.NEXT_PUBLIC_BOOKING_VERSION as string,
      entryPoint: 'travelPage',
      currentStep: -1,
      sentEvents: [],
      lastActivityTimestamp: Date.now(),
      travelId: travelId,
      switchedToWaitlistFallback: false,
      hasTransportTypes: false,
      travellers: [],
      babies: [],
      travellerRemarks: '',
      billingAddressForcedDueToMinorAge: false,
      billingAddress: {
        salutation: 'MR',
        firstName: '',
        lastName: '',
        birthday: '',
        email: '',
        phone: '',
        address: '',
        addressAddition: '',
        zip: '',
        city: '',
        country: '',
      },
      accommodationSelection: [],
      accommodationAddonSelection: [],
      personalAddonSelection: [],
      bookingAddonSelection: [],
      collectiveAddonSelection: [],
      personalDiscountSelection: [],
      bargainDiscountSelection: [],
      isUpgradedToExtension: false,
      ratioSummary: undefined,
      travelDocumentationService: 'electronic',
      customData: {},
      payment: {
        type: 'invoice',
      },
      co2compensation: false,
      newsletterRegistration: false,
      gtcAccepted: false,
      bookingRemarks: '',
      acquisitionChannel: '',
      bargainConfig: undefined,
      onlinePaymentConfig: {
        enabled: false,
        infoText: '',
      } as tenantOnlinePaymentConfigObject,
    })
    item = state.find((booking) => {
      return booking.travelId === travelId && booking.version === process.env.NEXT_PUBLIC_BOOKING_VERSION
    })
  }
  return item as bookingStateItem
}

export const getStateBooking = (state: bookingsState, travelId: number): bookingStateItem | undefined => {
  return state.find((booking) => {
    return booking.travelId == travelId && booking.version === process.env.NEXT_PUBLIC_BOOKING_VERSION
  })
}

export const getStateBookingByPaymentTransactionId = (state: bookingsState, transactionId: string): bookingStateItem | undefined => {
  return state.find((booking) => {
    return booking.payment.transactionId === transactionId
  })
}

export default bookingReducer
