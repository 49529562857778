import brandingConfig from '@/app/config/branding'
import travelFilterConfig from '@/app/config/travelFilter'
import googleMapConfig from '@/app/config/googleMap'
import bookingConfig from '@/app/config/booking'
import travelConfig from '@/app/config/travel'
import featuresConfig from '@/app/config/features'

let appConfig = {
  ...featuresConfig,
  ...brandingConfig,
  ...travelFilterConfig,
  ...googleMapConfig,
  ...bookingConfig,
  ...travelConfig,
}
export default appConfig
