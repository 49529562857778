export const isBrowser = typeof window !== 'undefined'
export const isServer = !isBrowser

export function uppercaseFirst(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function sharedStart(strings: string[]) {
  let A = strings.concat().sort(),
    a1 = A[0],
    a2 = A[A.length - 1],
    L = a1.length,
    i = 0
  while (i < L && a1.charAt(i) === a2.charAt(i)) i++
  return a1.substring(0, i)
}

export function toDbDate(date: Date) {
  return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`
}

export function stringDateToUTCDate(date: string) {
  return new Date(date + 'T00:00:00.000Z')
}

export function toUiDate(date: Date | string) {
  if (typeof date === 'string') {
    date = new Date(date)
  }
  return date.toLocaleDateString('de-CH', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'UTC',
  })
}

export const getToday = () => {
  let d = new Date()
  return new Date(d.toDateString())
}

export const getYesterday = (dateOnly = false) => {
  let d = new Date()
  d.setDate(d.getDate() - 1)
  return dateOnly ? new Date(d.toDateString()) : d
}

export const isFutureDate = (date: string | Date) => {
  if (typeof date === 'string') {
    date = new Date(date)
  }
  return new Date(date).getTime() > getToday().getTime()
}

export function dateRangeDays(start: Date | string, end: Date | string) {
  if (typeof start === 'string') {
    start = new Date(start)
  }
  if (typeof end === 'string') {
    end = new Date(end)
  }

  // @ts-ignore
  const diffTime = Math.abs(end - start)
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1
}

/**
 * Returns the index of the last element in the array where predicate is true, and -1
 * otherwise.
 * @param array The source array to search in
 * @param predicate find calls predicate once for each element of the array, in descending
 * order, until it finds one where predicate returns true. If such an element is found,
 * findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
 */
export function findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
  let l = array.length
  while (l--) {
    if (predicate(array[l], l, array)) return l
  }
  return -1
}

export function findLast<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): any {
  const index = findLastIndex(array, predicate)
  if (index >= 0) {
    return array[index]
  }
  return undefined
}

export function getAgeOnDate(dob: Date, proofDate: Date): number {
  var ageDifMs = proofDate.getTime() - dob.getTime()
  var ageDate = new Date(ageDifMs) // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970)
}

function onlyUnique(value: never, index: number, self: never[]) {
  return self.indexOf(value) === index
}

export function arrayUnique(array: []) {
  return array.filter(onlyUnique)
}

export function isInt(n: number) {
  return Number(n) === n && n % 1 === 0
}

export function isFloat(n: number) {
  return Number(n) === n && n % 1 !== 0
}

export function formatPrice(price: number, currency: string) {
  if (!price) {
    price = 0
  }
  const priceString =
    new Intl.NumberFormat('de-CH', {
      style: 'currency',
      currency: currency ?? 'CHF',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).format(price) + '.-'

  if (priceString.startsWith(`${currency}-`)) {
    return priceString.replace(`${currency}-`, `- ${currency} `)
  }
  return priceString
}

export function addLeadingZeros(num: number | string, size: number) {
  num = num.toString()
  while (num.length < size) num = '0' + num
  return num
}
