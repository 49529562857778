const KEY = 'redux'
export function loadState() {
  if (process.env.NEXT_PUBLIC_REDUX_LOCAL_STORAGE != '1') {
    return undefined
  }
  try {
    const serializedState = localStorage.getItem(KEY)
    if (!serializedState) return undefined
    const jsonState = JSON.parse(serializedState)

    // remove travelFilter (no permanent storage needed)
    delete jsonState.travelFilter

    return jsonState
  } catch (e) {
    return undefined
  }
}

export async function saveState(state: any) {
  if (process.env.NEXT_PUBLIC_REDUX_LOCAL_STORAGE != '1') {
    return
  }
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(KEY, serializedState)
  } catch (e) {
    // Ignore
  }
}
