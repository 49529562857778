import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import {
  SetInitialTravelFilterResponseFulfilledPayload,
  ApplyTravelFilterUrlQueryPayload,
  SetTravelFilterQueryPayload,
  SetTravelFilterSelectionPayload,
  TravelFilterResponsePayload,
} from '@/features/travelFilter/types'
import graphQLClient from '@/lib/api/graphQLClient'
import { buildTravelFilterRequestVariables, TRAVEL_FILTER_QUERY } from '@/features/travelFilter/gql'
import { getTravelFilterParamsFromRouter } from '@/features/travelFilter/helpers'

export const setTravelFilterQuery = createAction<SetTravelFilterQueryPayload>('travelFilter/setTravelFilterQuery')
export const setTravelFilterSelection = createAction<SetTravelFilterSelectionPayload>('travelFilter/setTravelFilterSelection')
export const setInitialTravelFilterResponse = createAction<TravelFilterResponsePayload>('travelFilter/setInitialTravelFilterResponse')
export const initializeTravelFilter = createAsyncThunk(
  'travelFilter/initializeTravelFilter',
  async (payload: any, thunkAPI): Promise<TravelFilterResponsePayload> => {
    const response = await graphQLClient.request(TRAVEL_FILTER_QUERY, buildTravelFilterRequestVariables())
    return {
      filterResponse: response.travelFilter,
    }
  }
)
export const applyTravelFilterUrlQuery = createAsyncThunk(
  'travelFilter/applyTravelFilterUrlQuery',
  async (payload: ApplyTravelFilterUrlQueryPayload, thunkAPI): Promise<SetInitialTravelFilterResponseFulfilledPayload> => {
    let filterResponse = payload.filterResponse

    // query with params (if any)
    const { hasParams, filterSelections, query } = getTravelFilterParamsFromRouter(payload.router)
    if (hasParams) {
      const response = await graphQLClient.request(TRAVEL_FILTER_QUERY, buildTravelFilterRequestVariables(filterSelections, query))
      filterResponse = response.travelFilter
    }

    return {
      filterResponse: filterResponse,
      filterSelections: filterSelections,
      query: query,
    }
  }
)
export const executeTravelFilterRequest = createAsyncThunk('travelFilter/executeTravelFilterRequest', async (payload: any, thunkAPI) => {
  // @ts-ignore
  const state = thunkAPI.getState().travelFilter
  const query = state.query
  const filterSelections = state.filterSelections
  const response = await graphQLClient.request(TRAVEL_FILTER_QUERY, buildTravelFilterRequestVariables(filterSelections, query))
  return {
    filterResponse: response.travelFilter,
  }
})
export const resetTravelFilter = createAsyncThunk('travelFilter/resetTravelFilter', async (payload: any, thunkAPI) => {
  const response = await graphQLClient.request(TRAVEL_FILTER_QUERY, buildTravelFilterRequestVariables())
  return {
    filterResponse: response.travelFilter,
  }
})
