import { SocialSharingMethod } from '@/interfaces'

declare const window: Window &
  typeof globalThis & {
    gtag: any
  }

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA4_ID

/**
 * COMMON EVENTS
 */

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  if (GA_TRACKING_ID) {
    window.gtag('config', GA_TRACKING_ID, {
      page_path: url,
    })
  }
}

// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#share
interface SendShareEventProps {
  method: SocialSharingMethod
  itemId: string
}
export const sendShareEvent = ({ method, itemId }: SendShareEventProps) => {
  if (GA_TRACKING_ID) {
    window.gtag('event', 'share', {
      method: method,
      item_id: itemId,
    })
  }
}

interface SendFormEventProps {
  formName: 'contact'
}
export const sendFormEvent = ({ formName }: SendFormEventProps) => {
  if (GA_TRACKING_ID) {
    window.gtag('event', `submit_${formName}_form`, {
      method: 'engagement',
    })
  }
}

export const sendNewsletterSignupEvent = () => {
  if (GA_TRACKING_ID) {
    window.gtag('event', `signup_newsletter`, {
      method: 'engagement',
    })
  }
}

/**
 * ECOMMERCE EVENTS
 */

// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#begin_checkout
interface SendCheckoutEventProps {
  travelCode: string
  travelName: string
  noOfTravellers: number
}
export const sendBeginCheckoutEvent = ({ travelCode, travelName, noOfTravellers }: SendCheckoutEventProps) => {
  if (GA_TRACKING_ID) {
    window.gtag('event', 'begin_checkout', {
      items: [
        {
          item_id: travelCode,
          item_name: travelName,
          quantity: noOfTravellers,
        },
      ],
    })
  }
}

export const sendCheckoutSelectServicesEvent = ({ travelCode, travelName, noOfTravellers }: SendCheckoutEventProps) => {
  if (GA_TRACKING_ID) {
    window.gtag('event', 'select_services', {
      event_category: 'ecommerce',
      items: [
        {
          item_id: travelCode,
          item_name: travelName,
          quantity: noOfTravellers,
        },
      ],
    })
  }
}

export const sendCheckoutTravellerDataEvent = ({ travelCode, travelName, noOfTravellers }: SendCheckoutEventProps) => {
  if (GA_TRACKING_ID) {
    window.gtag('event', 'traveller_data', {
      event_category: 'ecommerce',
      items: [
        {
          item_id: travelCode,
          item_name: travelName,
          quantity: noOfTravellers,
        },
      ],
    })
  }
}

export const sendCheckoutSelectInsuranceEvent = ({ travelCode, travelName, noOfTravellers }: SendCheckoutEventProps) => {
  if (GA_TRACKING_ID) {
    window.gtag('event', 'select_insurance', {
      event_category: 'ecommerce',
      items: [
        {
          item_id: travelCode,
          item_name: travelName,
          quantity: noOfTravellers,
        },
      ],
    })
  }
}

export const sendCheckoutPaymentInfoEvent = ({ travelCode, travelName, noOfTravellers }: SendCheckoutEventProps) => {
  if (GA_TRACKING_ID) {
    window.gtag('event', 'select_payment_method', {
      event_category: 'ecommerce',
      items: [
        {
          item_id: travelCode,
          item_name: travelName,
          quantity: noOfTravellers,
        },
      ],
    })
  }
}

export const sendCheckoutInitPaymentEvent = ({ travelCode, travelName, noOfTravellers }: SendCheckoutEventProps) => {
  if (GA_TRACKING_ID) {
    window.gtag('event', 'init_payment', {
      event_category: 'ecommerce',
      items: [
        {
          item_id: travelCode,
          item_name: travelName,
          quantity: noOfTravellers,
        },
      ],
    })
  }
}

// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#purchase
interface SendPurchaseEventProps {
  travelCode: string
  travelName: string
  orderNo: string
  totalPrice: number
  noOfTravellers: number
}

export const sendCheckoutPurchaseEvent = ({ travelCode, travelName, orderNo, totalPrice, noOfTravellers }: SendPurchaseEventProps) => {
  if (GA_TRACKING_ID) {
    window.gtag('event', 'purchase', {
      transaction_id: orderNo,
      currency: 'CHF',
      value: totalPrice,
      items: [
        {
          item_id: travelCode,
          item_name: travelName,
          quantity: noOfTravellers,
        },
      ],
    })
  }
}
