import { SWRConfig as SWRConfigComponent } from 'swr'
import { GraphQLClient } from 'graphql-request'
import { RequestDocument, Variables } from 'graphql-request/dist/types'

// create client
const graphQLClient = new GraphQLClient('/api/proxy', {
  credentials: 'include',
  mode: 'cors',
})
graphQLClient.setHeaders({
  'skag-tenant-id': process.env.NEXT_PUBLIC_TENANT_ID as string,
})
const fetcher = (query: RequestDocument, variables?: Variables) => graphQLClient.request(query, variables)

function SWRConfig(props: any) {
  return (
    <SWRConfigComponent
      value={{
        fetcher: fetcher,
        onError: (err) => {
          console.error(err)
        },
      }}
    >
      {props.children}
    </SWRConfigComponent>
  )
}

export default SWRConfig
