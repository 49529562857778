import CustomAppHeadContent from '@/components/template/AppHeadContent/CustomAppHeadContent'
import { getCloudinaryThemeFileUrl } from '@/integrations/cloudinary/helpers'

const AppHeadContent = () => {
  return (
    <>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" href={getCloudinaryThemeFileUrl('favicon/favicon.ico')} />
      <link rel="apple-touch-icon" sizes="180x180" href={getCloudinaryThemeFileUrl('favicon/apple-touch-icon.png')} />
      <link rel="icon" type="image/png" sizes="32x32" href={getCloudinaryThemeFileUrl('favicon/favicon-32x32.png')} />
      <link rel="icon" type="image/png" sizes="16x16" href={getCloudinaryThemeFileUrl('favicon/favicon-16x16.png')} />
      <link rel="manifest" href={getCloudinaryThemeFileUrl('favicon/site.webmanifest', 'raw')} />
      <meta name="msapplication-config" content={getCloudinaryThemeFileUrl('favicon/browserconfig.xml', 'raw')} />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />
      <title>{process.env.NEXT_PUBLIC_SITE_NAME}</title>
      <meta property="og:site_name" content={process.env.NEXT_PUBLIC_SITE_NAME} />
      <meta property="og:locale" content="de_CH" />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:site" content={process.env.NEXT_PUBLIC_SITE_NAME} />
      <CustomAppHeadContent />
    </>
  )
}

export default AppHeadContent
