import React from 'react'

interface IconProps {
  name: string
  variant?: string // bootstrap color variant
  size?: number // in 'rem'
  className?: string
}

const Icon = ({ name, variant, size = 1, className = '' }: IconProps) => {
  const variantClass = variant !== undefined ? 'fill-' + variant : ''
  return (
    <>
      <svg className={`${variantClass} ${className}`}>
        <use xlinkHref={`#${name}`} />
      </svg>
      <style jsx>{`
        svg {
          height: ${size}rem;
          width: ${size}rem;
        }
      `}</style>
    </>
  )
}

export default Icon
